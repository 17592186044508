import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AuthProvider } from './contexts/JWTContext';

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import 'antd/dist/antd.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthProvider>

      <App />
      </AuthProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

