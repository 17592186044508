import { createContext, useEffect, useState } from "react";

import authAxios from '../utils/axios';
import { isValidToken, setSession } from "../utils/jwt";
import { api_route } from "../assets/Api-Config/config";
// import LoadingScreen from "../components/LoadingScreen";
import { message } from "antd";
import LoadingScreen from "../Components/LoadingScreen/LoadingScreen";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [auth, setAuth] = useState(false);
    const [logSucess, setLogSucess] = useState(false);
    
    const handleClose = () => {
        setOpen(false);
    };
    // const navigate = useNavigate();

    const fetchUser = async () => {
        
        try {
            setLoading(true);
            // setLoading(false);
            setOpen(true);
            const accessToken = window.localStorage.getItem('token');
            
            // if (accessToken && isValidToken(accessToken)) {
            if (accessToken) {
                setSession(accessToken);
                setAuth(true);
                setLogSucess(true)
                const response = await axios.get(`${api_route}/me`, {
                    headers: {
                        "token": accessToken,
                    },
                });

                
                var year = new Date(response.data.dateOfBirth);
                var fullYear = year.getFullYear();
                setUser({...response.data,fullYear:fullYear});
                // navigate("/account/dashboard")
                setLoading(false);
            } else {

                setUser(null);
                setLoading(false);
                
            }
        }

        catch (error) {
            message.error(error)
            setUser(null);
            

        }
    }


    useEffect(() => {
        if (user === null) {

            fetchUser();
        }
    }, []);

    const login = async ({ email, password, confirmPassword }) => {
        

        try {

            const response = await authAxios.post(`${api_route}/login`, {
                email,
                password,
                confirmPassword
            });
            const data = response.data;
            // if (data.success) {
            const { token, user } = data;
            setSession(token);
            setUser(user)
            setLogSucess(true)
            await fetchUser();
            
            return { success: true, error: "" };
            // } else {
            //     return { success: false, error: data.error };
            // }
        }
        catch (err) {
            
            return { success: false, error: err };
        }


    }


    const logout = async () => {
        setSession(null);
        setUser(null);
    }

    const values = {
        auth,
        user,
        setUser,
        login,
        logout,
        fetchUser,
        open,
        setOpen,
        logSucess,
        handleClose,
        loading
    }
    return (
        <AuthContext.Provider value={values}>
            {loading ? <LoadingScreen /> : children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider };