import { useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Login from "../Components/Login/Login";

import useAuth from "../hooks/useAuth";



export default function AuthGuard({children}){
    const {user} = useAuth();
    const {pathname} = useLocation();
    
    const [requestLocation, setRequestLogin] = useState(null);
    if(!user){
        
        if(pathname !== requestLocation){
            setRequestLogin(pathname);
        }
        return <Navigate to="/login"/>
        
    }

    if(requestLocation && pathname !== requestLocation){
        setRequestLogin(null);
        return <Navigate to = {requestLocation} />
    }

    return <Outlet/>
}