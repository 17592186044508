import React, { useState, useEffect } from "react";
import styles from "./Login.module.css";

import { Link, useNavigate } from "react-router-dom";

import { api_route } from "../../assets/Api-Config/config";

// Material Components
import {
  Container,
  Stack,
  Box,
  Card,
  Typography,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Modal,
} from "@mui/material";

import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";

import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

// Image
import logo from "../../assets/Images/brandLogo.jpg";
import loginImg from "../../assets/Images/loginImg.png";

// Icons
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";

// Styled Components
import { createGlobalStyle } from "styled-components";

import axios from "axios";

import { notification } from "antd";

import useAuth from '../../hooks/useAuth';
import CancelIcon from "@mui/icons-material/Cancel";


const GlobalStyle = createGlobalStyle`
  body {
    background-color: #e53267;
  }
`;

const Login = () => {
  const navigate = useNavigate();
  const {auth, login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openForgotModal,setOpenForgotModal] = useState(false);


const handleOpenForgotPasswordModal = () =>{
  setOpenForgotModal(true)
}

const handleSentLInkToChangePassword = async() =>{
console.log(formikValues.values.email)
setLoading(true)
try{
  let email = formikValues.values.email;
  const payload = {
    "email":email
  }
  const res = await axios.put(`${api_route}/requestChangePassword`, payload);
  console.log(res)
  notification.success({
    message: "Success",
    description: res.data,
    placement: "topRight",
    duration: 2,
    style: {
      marginTop: "50px",
      textTransform: "capitalize",
    },
  });
  setOpenForgotModal(false)
  setLoading(false)
}
catch(err){
  notification.error({
    message: "Error",
    description: err.message,
    placement: "topRight",
    duration: 2,
    style: {
      marginTop: "50px",
      textTransform: "capitalize",
    },
  });
  setLoading(false)
}
}

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Minimum 8 characters required")
      .required("Password is required"),
  });

  const formikValues = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors, resetForm }) => {
      const body = {
        email: values.email,
        password: values.password,
        confirmPassword: values.password,
      };
      
      try {
        setLoading(true);
        const res = await login(body);
        
        setLoading(false);

        // const res = await axios.post(`${api_route}/login`, body);
        // 
        // 
        // var dobDate = new Date(res.data.user.dateOfBirth);
        // var dob = `${dobDate.getDate()}/${
        //   dobDate.getMonth() + 1 < 10
        //     ? `0${dobDate.getMonth() + 1}`
        //     : dobDate.getMonth() + 1
        // }/${dobDate.getFullYear()}`;
        // localStorage.setItem("token", res.data.token);
        // localStorage.setItem("profilePicture", res.data.user.profilePicture);
        // localStorage.setItem("_id", res.data.user._id);
        // localStorage.setItem("firstName", res.data.user.firstName);
        // localStorage.setItem("lastName", res.data.user.lastName);
        // localStorage.setItem("dob", dob);
        // localStorage.setItem("city", res.data.user.city);
        resetForm();
        //  navigate("/account/dashboard");

        if(res.success){

          navigate("/account/dashboard");
          notification.success({
            message: "Welcome to BOI!",
            description: "Login Successfully",
            duration: 3,
            style: {
              marginTop: "50px",
              textTransform: "capitalize",
            },
          });
        
        }
        else{
          notification.error({
            message: "Error",
            description: " user not found Plese provide valid user ID and Password",
            duration: 3,
            style: {
              marginTop: "50px",
              textTransform: "capitalize",
            },
          });
          // 
          // await sleep(1000)
          // 
          // window.open('https://ipg.bobgateway.com/IPG/PrePaymentPage.htm?param=paymentInit&tranportalid=295A0CA70A6F722F', '_blank');

        // window.location.replace("http://www.w3schools.com");

              }
      } catch (err) {
        
        notification.error({
          message: "Error",
          description: err.response.data,
          duration: 3,
          style: {
            marginTop: "50px",
            textTransform: "capitalize",
          },
        });
      }
    },
  });

  function sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const { touched, errors, handleSubmit, getFieldProps } = formikValues;

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  // const handleShowConfirmPassword = () => {
  //   setShowConfirmPassword((show) => !show);
  // };

  const color = "#e53267";

  return (
    <>
      <GlobalStyle />
      {loading ? (
        <LoadingScreen />
      ) : (
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: { lg: "100vh" },
          }}
        >
          <Card component={Stack} sx={{ borderRadius: "0" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{ bgcolor: "#FFECF8" }}
              >
                <Link to={"/"}>
                  <img src={logo} alt="logo" width={150} />
                </Link>
                <img src={loginImg} className={styles.img} alt="login" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: "16px" }}>
                <Box
                  mb={5}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "end",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <Typography>Don't have an account?</Typography>

                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      border: "1px solid #e53267",
                      borderRadius: "500px",
                      color: "#e53267",
                      "&:hover": {
                        border: "1px solid #e53267",
                        color: "#e53267",
                      },
                    }}
                    onClick={() => navigate("/register")}
                  >
                    Register
                  </Button>
                </Box>

                <Typography mb={1} variant="h5">
                  Welcome to <span style={{ color: "#e53267" }}>BOI!</span>
                </Typography>
                <Typography mb={5} variant="h6">
                  Find your perfect match, Please enter your details.
                </Typography>

                <FormikProvider value={formikValues}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={2} mb={2} direction="column">
                      <Stack sx={{ width: "100%" }}>
                        <TextField
                          fullWidth
                          required
                          size="small"
                          placeholder="Email"
                          type="email"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": { borderColor: color },
                            },
                          }}
                          {...getFieldProps("email")}
                          error={touched.email && errors.email}
                          helperText={touched.email && errors.email}
                        />
                      </Stack>

                      <Stack sx={{ width: "100%" }}>
                        <TextField
                          fullWidth
                          required
                          size="small"
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": { borderColor: color },
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleShowPassword}
                                  edge="end"
                                >
                                  <Icon
                                    icon={showPassword ? eyeFill : eyeOffFill}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...getFieldProps("password")}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </Stack>
                    </Stack>

                    {/* <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <Link
                        onClick={() => navigate("/forgot-password")}
                        underline="hover"
                      >
                        Forgot Password
                      </Link>
                    </Box> */}

                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        borderRadius: "500px",
                        backgroundColor: color,
                        color: "white",
                        "&:hover": {
                          backgroundColor: color,
                          color: "white",
                        },
                      }}
                    >
                      Login
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleOpenForgotPasswordModal}
                      sx={{
                        borderRadius: "500px",
                        backgroundColor: color,
                        color: "white",
                        "&:hover": {
                          backgroundColor: color,
                          color: "white",
                        },
                        marginLeft:"10px",

                      }}
                    >
                      Forgot Password
                    </Button>
                  </Form>
                </FormikProvider>
              </Grid>
            </Grid>
          </Card>
      <Modal open={openForgotModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "100%", sm: "60%", md: "40%", lg: "30%" },

            overflowY: "auto",
            scrollBehavior: "smooth",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
          }}
        >
          <Stack direction="row" justifyContent="flex-end">
            <IconButton color="error" onClick={() => setOpenForgotModal(false)}>
              <CancelIcon />

            </IconButton>
          </Stack>
          <Box mt={2} sx={{ display: "flex", justifyContent: "center", alignContent: "center", alignContent: "center" }}>
            <Stack>

            <Stack sx={{ width: "100%" }}>
                        <TextField
                          fullWidth
                          required
                          size="small"
                          placeholder="Email"
                          type="email"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": { borderColor: color },
                            },
                          }}
                          {...getFieldProps("email")}
                          error={touched.email && errors.email}
                          helperText={touched.email && errors.email}
                        />
                      </Stack>
              <Stack direction="row" width="100%" mt={3} spacing={3}>
                <Button variant="contained" color="error" onClick={() => setOpenForgotModal(false)}>Cancel</Button>
                <Button variant="contained" 
                onClick={handleSentLInkToChangePassword}
                 >Confirm</Button>
              </Stack>



            </Stack>

          </Box>

        </Box>
      </Modal>
        </Container>
      )}

    </>
  );
};

export default Login;
