import { useState, useEffect, lazy, Suspense } from "react";

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from "./ErrorBoundary";

import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import AuthGuard from "./guards/AuthGuard";

// Components
const Header = lazy(() => import("./Components/Header/Header"));
const Login = lazy(() => import("./Components/Login/Login"));
const LoadingScreen = lazy(() => import("./Components/LoadingScreen/LoadingScreen"));
const DashboardLayout = lazy(() => import("./Components/Layouts/index"));
const Dashboard = lazy(() => import("./Components/Dashboard/Dashboard"));
const FindMatch = lazy(() => import("./Components/FindYourMatch/FindMatch"))
const YourMatches = lazy(() => import("./Components/YourMatch/YourMatch"));
const IncomingPendingRequest = lazy(() => import("./Components/IncomingPendingRequest/IncomingPendingRequest"));
const SentPendingRequest = lazy(() => import("./Components/SentPendingRequest/SentPendingRequest"));
const Profile = lazy(() => import("./Components/Profile/Profile"));
const Register = lazy(() => import("./Components/Register/Register"));
const ForgotPassword = lazy(() => import("./Components/ForgotPassword/ForgotPassword"));
const TestingChat = lazy(() => import("./Components/Chats/testingChat"));

// Pages
const Home = lazy(() => import("./Pages/Home/Home"));
const About = lazy(() => import("./Pages/About/About"));
const Pricing = lazy(() => import("./Pages/Pricing/Pricing"));
const Contact = lazy(() => import("./Pages/ContactUs/Contact"));

function App() {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const clearCacheData = () => {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });

    };
    clearCacheData();
  }, [])

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      setLoading(true);
    }, 2000);
  }, []);

  return (
    loading ? (
      <Router>
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {
          window.location.reload();
        }}>
        <Suspense fallback={<LoadingScreen />}>
          <Header />
          <Routes >
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password/:token" element={<ForgotPassword />} />


            {/* Protected Routers */}
            <Route element={<AuthGuard />}>
              <Route path="/account" element={<DashboardLayout />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="findYourMatch" element={<FindMatch />} />
                <Route path="yourMatches" element={<YourMatches />} />
                <Route
                  path="sentpendingRequests"
                  element={<SentPendingRequest />}
                />
                <Route
                  path="incomingpendingRequests"
                  element={<IncomingPendingRequest />}
                />
                <Route path="profile" element={<Profile />} />
                <Route path="chat" element={<TestingChat />} />



              </Route>
            </Route>






          </Routes>
        </Suspense>
        </ErrorBoundary>
      </Router>
    ) : (
      <LoadingScreen />
    )
  );
}

export default App;
