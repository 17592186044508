import jwtDecode from 'jwt-decode';

// Do not use it, it send issues 
// import {verify, sign} from 'jsonwebtoken';

import authAxios from './axios';

// ****************** check is token is valid ************************

const isValidToken = (accessToken) => {
    if(!accessToken){
        return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
    // return true;
}

const setSession = (accessToken) => {
    if(accessToken){
        window.localStorage.setItem('token', accessToken);
        authAxios.defaults.headers.common.token = `${accessToken}`;
    }else{
        window.localStorage.removeItem("token");
        delete authAxios.defaults.headers.common.token;
    
    }
}

// export {isValidToken, setSession, verify, sign};
export {isValidToken, setSession};