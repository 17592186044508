import React from 'react';

import {
    Container,
    Typography,
    Button,
} from '@mui/material';

function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <Container maxWidth="md" sx={{ mt: 5 }}>
            <Typography variant='h3'>Something went wrong:</Typography>
            <Typography mb={2} variant="subtitle1">{error.message}</Typography>
            <Button variant="contained" onClick={resetErrorBoundary}>Try again</Button>
        </Container>
    )
}

export default ErrorFallback;